
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { bus } from "@/main";

@Component({
    components: {
        Slideout: () => import("@/layouts/components/slideout.vue"),
    },
})
export default class MoreSlideout extends Vue {
    @Getter("auth/me") account!: CurrentAccount;

    @Prop() vertical!: boolean;
    show: boolean = false;

    created() {
        bus.$on("show-more-slideout", () => {
            this.show = true;
        });
    }

    handleClose() {
        this.show = false;
    }
}
